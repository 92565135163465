const ErrorPage = () => {
  return (
    <>
      <div className="error--internal s-parent">
        <div className="wrapper flex flex-col items-start px-4 py-24 gap-2">
          <div className="title">Error</div>
          <div className="description">Internal error — try again later.</div>
        </div>
      </div>
    </>
  );
};

export { ErrorPage };
